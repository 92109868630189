@import '../../../styles/customMediaQueries.css';

.container {
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;

  @media (--viewportXLarge) {
    max-width: var(--contentLgMaxWidthPages);
  }

  @media (--viewportMediumScreen) {
    max-width: 100%;
    padding: 0 60px;
  }
}

.creatorLoveWrap {
  padding: 100px 0 160px;
  background-color: #500543;
  border-radius: 80px;
  display: block;

  @media (--viewportMediumScreen) {
    padding: 100px 0 140px;
  }

  @media (--viewportFinalSmallScreen) {
    border-radius: 40px;
    padding: 70px 0 90px;
  }

  @media (--viewportMSScreen) {
    display: none;
  }
}

.creatorLoveContainer {
  position: relative;
}

.creatorLoveHeading {
  color: #fff;
  font-size: 25px;
  font-family: 'Lexend', sans-serif;
  text-align: left;
  font-weight: 800;

  @media (--viewportLarge) {
    font-size: 48px;
    line-height: 1.2;
  }

  @media (--viewportXMScreen) {
    font-size: 36px;
    line-height: 46px;
  }
  @media (--viewportXSScreen) {
    font-size: 32px;
    line-height: 42px;
  }

  @media (--viewportFinalSmallScreen) {
    font-size: 28px;
    line-height: 38px;
  }
}

.creatorLoveSlider {
  background-color: #f3f4f21a;
  padding: 8px;
  border-radius: 32px;
  margin: 0 10px;
  height: 100%;
}

.creatorSliderCont {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
}

.creatorAuthWrap {
  padding: 24px;
}

.creatorSlideContWrap {
  text-align: left;
}

.creatorSlideHead {
  font-size: 18px;
  line-height: 25px;
  color: #fff;
  font-weight: var(--fontWeightMedium);
  margin: 0;
  padding-bottom: 5px;

  @media (--viewportFinalSmallScreen) {
    font-size: 16px;
  }
}

.creatorSlideLabel {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: #fff;
  margin: 0;
  padding: 0;
}

.creatorSlideText {
  margin: 0;
}

.text {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #fff;
  text-align: left;
  margin: 16px 0 0;
  padding: 0;

  @media (--viewportFinalSmallScreen) {
    font-size: 14px;
  }
}

.leftArrow,
.rightArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: absolute;
  top: -60px;
  z-index: 99;
  bottom: auto;
  border: 0;
}

.leftArrow {
  left: auto;
  right: 50px;
}

.rightArrow {
  right: 0;
}

.leftArrow:hover,
.rightArrow:hover {
  transform: scale(1.1); /* Slight zoom on hover */
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.leftArrow:disabled,
.rightArrow:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.arrow {
  width: 20px;
  height: auto;
}

.carousel.carousel-slider {
  overflow: visible;
}
