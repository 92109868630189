@import '../../../styles/customMediaQueries.css';

.root {
  background-color: white;
  min-height: unset;

  @media (--viewportMedium) {
    min-height: 100%;
  }
}

.container {
  /* background-color: #fff8fe; */
  min-height: unset;

  @media (--viewportMedium) {
    min-height: 100%;
  }
}

.title {
  font-size: 30px;
  color: var(--colorWhite);
  line-height: 1.2;
  font-family: 'Lexend', sans-serif;
  margin-top: 0px;

  @media (--viewportMedium) {
    font-size: 96px;
    line-height: 106px;
  }

  @media (--viewportMediumScreen) {
    font-size: 80px;
    line-height: 90px;
  }

  @media (--viewportMSScreen) {
    font-size: 60px;
    line-height: 70px;
  }

  @media (--viewportXSScreen) {
    font-size: 44px;
    line-height: 54px;
  }

  @media (--viewportFinalSmallScreen) {
    font-size: 28px;
    line-height: 38px;
  }
}

.subtitle {
  font-size: 16px;

  @media (--viewportMedium) {
    font-size: 20px;
    margin-bottom: 1.2rem;
  }
}

.hero {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url('../../../assets/photos/final-hero.png');
  min-height: 270px;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: -91px; */
  justify-content: center;
  border-radius: 80px;
  padding: 120px 0;
  margin: 0 30px;

  @media (max-width: 767px) {
    margin: 0 15px;
  }

  @media (--viewportFinalSmallScreen) {
    padding: 60px 0;
  }
}

.textContainer {
  width: 100%;
  text-align: center;

  @media (--viewportMedium) {
    width: 80%;
  }

  @media (--viewportXLarge) {
    width: 70%;
  }

  @media (--viewportXSScreen) {
    padding: 0 15px;
  }
}

.lines {
  max-height: 400px;
  width: 100%;
}

.heroImage {
  position: relative;
  width: 100%;
  margin-bottom: 3rem;

  @media (--viewportMedium) {
    /* width: 60%; */
    margin-bottom: unset;
  }
}

.sectionImage {
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    width: 60%;
    margin-bottom: 3rem;
  }
}

.hireCreator {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: white;
  color: black;
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(248, 49, 148, 1) 0%, #feaf64 100%);

  width: 163px;
  border: 1px solid var(--colorBlack);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  padding: 0.5rem 1.5rem;
  left: 5px;
  top: 5px;

  @media (--viewportMedium) {
    left: -8%;
    top: 20%;
  }

  &:hover {
    text-decoration: none;
    background: rgba(248, 49, 148, 1);
  }
}

.beCreator {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: white;
  color: black;
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(248, 49, 148, 1) 0%, #feaf64 100%);
  border: 1px solid var(--colorBlack);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  width: 163px;
  padding: 0.5rem 1.5rem;
  right: 5px;
  bottom: 16%;

  @media (--viewportMedium) {
    right: -10%;
    bottom: 15%;
  }

  &:hover {
    text-decoration: none;
    background: rgba(248, 49, 148, 1);
  }
}

.contact {
  height: 35px;
  margin-right: 0.25rem;
}

.text {
  margin: 0;
  line-height: 1.2;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
}

.heroImageContainer {
  display: flex;
  justify-content: center;
  padding: 1rem;

  @media (--viewportMedium) {
    margin-top: -10rem;
    padding: unset;
  }
}

.heroButton {
  background: #f3f4f2d9;
  border-radius: 50px;
  color: #191a1f;
  font-size: 16px;
  padding: 12px 30px 11px 3px;
  margin-top: 0.35rem;
  font-weight: 500;
  transition: 0.3s all ease-in-out 0s;

  &:hover {
    text-decoration: none;
    background: #fff;
    transition: 0.3s all ease-in-out 0s;
  }

  @media (--viewportXSScreen) {
    font-size: 15px;
    line-height: 19px;
  }
}

.redDot {
  color: red;
  font-size: 80px;
  margin-right: 0.5rem;
  position: absolute;
  top: 20%;
  left: 60%;
}

.redDotBig {
  color: red;
  font-size: 100px;
  margin-right: 0.5rem;
  position: absolute;
  top: 80%;
  left: 5%;
}

.orangeDot {
  color: orange;
  font-size: 80px;
  margin-right: 0.5rem;
  position: absolute;
  top: 55%;
  left: 30%;
}

.orangeDotRight {
  color: orange;
  font-size: 80px;
  margin-right: 0.5rem;
  position: absolute;
  top: 55%;
  right: 20%;
}

.redDotRight {
  color: red;
  font-size: 80px;
  margin-right: 0.5rem;
  position: absolute;
  top: 70%;
  right: 5%;
}

.redSmallLeft {
  color: red;
  font-size: 70px;
  margin-right: 0.5rem;
  position: absolute;
  top: 30%;
  left: 5%;
}

.icon {
  font-size: 40px;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  color: white;
}

.smallTextFinal {
  font-size: 20px;
  font-weight: 900;
  background: linear-gradient(90deg, #986ff0, #ff827e, #ffc24f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  line-height: 30px;

  @media (--viewportXSScreen) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (--viewportFinalSmallScreen) {
    font-size: 14px;
    line-height: 18px;
  }
}
.heroBtnWrap {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;

  @media (--viewportXSScreen) {
    flex-direction: column;
  }
}
.smallTextWrap {
  display: inline-block;
  background: #191a1fb3;
  padding: 10px 30px;
  border-radius: 50px;

  @media (--viewportFinalSmallScreen) {
    padding: 10px;
  }
}

.heroButtonIcon {
  background: #fff;
  border-radius: 50px;
  padding: 10px 9px;
  margin-right: 5px;

  &:hover {
    background: #f3f4f2d9;
  }
}
