@import '../../../styles/customMediaQueries.css';

.container {
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;

  @media (--viewportXLarge) {
    max-width: var(--contentLgMaxWidthPages);
  }

  @media (--viewportMediumScreen) {
    max-width: 100%;
    padding: 0 60px;
  }

  @media (--viewportFinalSmallScreen) {
    padding: 0 20px;
  }
}

.creatorEcoWrap {
  padding: 0px 0 160px;
  background-color: #fff;
  display: block;

  @media (--viewportMediumScreen) {
    padding: 0px 0 140px;
  }

  @media (--viewportFinalSmallScreen) {
    padding: 20px 0 90px;
  }

  @media (--viewportMSScreen) {
    display: none;
  }
}

.creatorEcoContainer {
  position: relative;
}

.creatorEcoHeading {
  color: #191a1f;

  @media (--viewportLarge) {
    font-size: 48px;
    line-height: 1.2;
  }

  @media (--viewportXMScreen) {
    font-size: 36px;
    line-height: 46px;
  }
  @media (--viewportXSScreen) {
    font-size: 32px;
    line-height: 42px;
  }

  @media (--viewportFinalSmallScreen) {
    font-size: 28px;
    line-height: 38px;
  }
}

.creatorEcoSlider {
  background-color: #f3f4f2;
  padding: 8px;
  border-radius: 32px;
  margin: 0 10px;
  height: 100%;
}

.creatorEcoSlider img {
  border-radius: 32px;
}

.creatorSliderCont {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  color: #191a1f;
}

.creatorAuthWrap {
  padding: 24px;
  color: #191a1f;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  margin: 0;
  text-align: left;
}

.creatorSlideContWrap {
  text-align: left;
}

.creatorSlideHead {
  font-size: 18px;
  line-height: 25px;
  color: #191a1f;
  font-weight: var(--fontWeightMedium);
  margin: 0;
  padding-bottom: 5px;

  @media (--viewportFinalSmallScreen) {
    font-size: 16px;
  }
}

.creatorSlideLabel,
.creatorSlideDate {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: #191a1f;
  margin: 0;
  padding: 0;
}

.creatorSlideText {
  margin: 0;
}

.text {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #191a1f;
  text-align: left;
  margin: 16px 0 0;
  padding: 0;

  @media (--viewportFinalSmallScreen) {
    font-size: 14px;
  }
}

.leftArrow,
.rightArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: absolute;
  top: -60px;
  z-index: 99;
  bottom: auto;
  border: 0;
}

.leftArrow {
  left: auto;
  right: 50px;
}

.rightArrow {
  right: 0;
}

.leftArrow:hover,
.rightArrow:hover {
  transform: scale(1.1); /* Slight zoom on hover */
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.leftArrow:disabled,
.rightArrow:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.arrow {
  width: 20px;
  height: auto;
}

.carousel.carousel-slider {
  overflow: visible;
}

.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none;
  border-radius: 32px;
}

.creatorEcoLabelWrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

a:hover {
  text-decoration: none;
}

@media (max-width: 550px) {
  .carousel .slide {
    text-align: left;
  }
  .creatorAuthWrap {
    color: #191a1f;
    font-size: 14px;
    font-weight: 300;
  }
  .creatorEcoLabelWrap {
    margin-bottom: 16px;
  }
}
