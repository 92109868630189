@import '../../../styles/customMediaQueries.css';

.root {
  min-height: unset;
  padding: 120px 0;
  background-color: #f3f4f2;
  border-radius: 80px;

  @media (--viewportMedium) {
    min-height: 100%;
  }

  @media (--viewportMediumScreen) {
    padding: 60px;
  }

  @media (--viewportFinalSmallScreen) {
    border-radius: 40px;
    padding: 50px 20px;
  }
}

.container {
  /* background-color: #fff8fe; */
  min-height: unset;
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;

  @media (--viewportXLarge) {
    max-width: var(--contentLgMaxWidthPages);
  }
  @media (--viewportMediumScreen) {
    max-width: 100%;
  }
}

.rewardCollabContWrap {
  display: flex;
  align-items: center;
  gap: 24px;

  @media (--viewportMSScreen) {
    flex-direction: column-reverse;
  }
}

.rewardLabel {
  font-size: 14px;
  color: #191a1f;
  line-height: 20px;
  font-weight: 500;
  background-color: #ffc24f;
  padding: 12px 16px;
  border-radius: 50px;
}

.rewardHeading {
  text-align: center;
  margin: 30px 0 46px;
  font-size: 25px;
  color: #191a1f;
  padding-top: unset;
  font-family: 'Lexend', sans-serif;
  text-align: left;
  width: 80%;
  font-weight: 800;

  @media (--viewportLarge) {
    font-size: 48px;
    line-height: 1.2;
  }

  @media (--viewportXMScreen) {
    font-size: 36px;
    line-height: 46px;
  }

  @media (--viewportXSScreen) {
    font-size: 32px;
    line-height: 42px;
  }

  @media (--viewportFinalSmallScreen) {
    font-size: 28px;
    line-height: 38px;
  }

  /* margin-bottom: -4rem; */
}

.rewardIconCont {
  display: flex;
  align-items: start;
  gap: 15px;
  margin-bottom: 16px;
  position: relative;

  @media (--viewportMSScreen) {
    flex-direction: column;
    padding: 0 !important;
  }
}

.rewardIconCont:not(:last-child) {
  border-bottom: 1px solid #191a1f1a;
  padding-bottom: 16px;
}

.rewardInnerHeading {
  font-size: 20px;
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
  color: #191a1f;
  margin: 0;

  @media (--viewportXMScreen) {
    font-size: 18px;
    line-height: 22px;
  }

  @media (--viewportFinalSmallScreen) {
    font-size: 16px;
    line-height: 22px;
  }
}

.rewardText {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #191a1f;
  margin: 0;

  @media (--viewportXMScreen) {
    font-size: 14px;
    line-height: 20px;
  }
}

.rewardContWrap {
  padding-left: 40px;
  width: 50%;

  @media (--viewportMSScreen) {
    padding: 0;
  }

  @media (--viewportXSScreen) {
    padding-left: 0;
  }
}

.partnerRightImg,
.rewardContWrap {
  width: 50%;

  @media (--viewportXMScreen) {
    width: 50%;
  }

  @media (--viewportMSScreen) {
    width: 100%;
  }
}

.partnerRightImgFinal {
  width: 100%;
}
